<script>
export default {
  name: 'NoticeAlert',
  props: {
    title: {
      default: null,
      required: false,
      type: String,
    },
    body: {
      required: true,
      type: String,
    },
    icon: {
      default: 'mdi-alert',
      required: false,
      type: String,
    },
  }
}
</script>

<template>
  <div v-if="title || body" class="notice-alert-wrapper">
    <v-alert
      class="body-2 text"
      outlined
      dense
      color="hqsOrange"
      :icon="icon"
    >
      <div v-if="title" v-html="title" />
      <div v-if="body" v-html="body" />
    </v-alert>
  </div>
</template>
